import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Blog } from 'ui/screens';

const BlogPage = ({ data, location }) => {
  return <Blog data={data} location={location}></Blog>;
};

BlogPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
};

BlogPage.defaultProps = {};

export default BlogPage;

export const BlogPageQuery = graphql`
  query {
    allGhostPost(
      filter: { visibility: { eq: "public" } }
      sort: { fields: [published_at], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          excerpt
          published_at
          html
          feature_image
          visibility
          tags {
            id
            name
            slug
          }
          primary_tag {
            id
            slug
          }
          authors {
            id
            slug
          }
        }
      }
    }
  }
`;
